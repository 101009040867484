<template>
  <CRow>
    <CCol sm="12">

      <CCard>

        <CCardHeader>
          <CIcon name="cil-text"/>
          Bestehenden Textbaustein ändern
        </CCardHeader>

        <CCardBody>
          <ValidationObserver v-slot="{ invalid }">
            <CForm class="no-mb" @submit.prevent="updateTextbaustein()">

              <TextbausteinText v-model="name" formLabel="Name"/>
              <TextbausteinTextArea v-model="text" formLabel="Text des Textbausteins" rules="required"/>

              <FormError v-if="formError" :errormessage="formError"/>

              <CRow class="mt-4">
                <CCol col="12" class="text-right">
                  <CButton color="primary" class="px-4" :disabled="invalid" type="submit">Textbaustein ändern</CButton>
                </CCol>
              </CRow>

            </CForm>
          </ValidationObserver>
        </CCardBody>

      </CCard>

    </CCol>
  </CRow>
</template>

<script>
import Vue from 'vue'
import FormError from '@/components/FormError'
import TextbausteinText from '@/components/FormElements/Text'
import TextbausteinTextArea from '@/components/FormElements/TextArea'

export default {
  name: 'MitarbeiterEdit',
  components: {
    FormError,
    TextbausteinText,
    TextbausteinTextArea
  },
  props: {
    uuid: [Number, String]
  },
  data () {
    return {
      name: '',
      text: '',
      formError: null
    }
  },
  mounted () {
    if (this.uuid) {
      Vue.axios.get('/textbaustein/get/' + this.uuid)
        .then((response) => {
          this.name = response.data.name
          this.text = response.data.text
        })
    }
  },
  methods: {
    updateTextbaustein () {
      Vue.axios.put('/textbaustein/put/' + this.uuid, {
        name: this.name,
        text: this.text
      }).then((response) => {
        this.$router.push({ path: '/textbausteine/uebersicht' })
        this.$snotify.success('Der Textbaustein wurde angepasst.', {
          position: 'rightTop',
          timeout: 4000
        })
      }).catch((error) => {
        this.formError = error.response.data
      })
    }
  }
}
</script>
